<template>
  <div class="costDetails">
    <div class="main">
      <div class="sub">
        <div class="title">迈瑞医疗HJ2313424放射仪</div>
        <div class="cost">
          <div class="spaceBetween">
            <span>起步价</span>
            <span>30元</span>
          </div>
          <div class="spaceBetween">2022-12-12 08:27:22</div>
          <div class="spaceBetween">
            <span>优惠抵扣</span>
            <span>30元</span>
          </div>
          <div class="solid"></div>
          <div class="spaceBetween">
            <span>总费用</span>
            <span>30元</span>
          </div>
          <div class="paidIn">
            <div>实付<span class="price">30.0</span>元</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">确定</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.costDetails {
  height: 100%;
  padding: 10px;
  .main {
    height: calc(100% - 76px);
    .sub {
      background: #ffffff;
      border-radius: 12px;
      padding-top: 10px;
      .title {
        font-size: 14px;
        color: $color1;
        font-weight: 500;
        text-align: center;
        padding-bottom: 6px;
      }
      .cost {
        padding: 0 76px;
        font-size: 12px;
        .spaceBetween {
          display: flex;
          justify-content: space-between;
          line-height: 24px;
        }
        .solid {
          width: 100%;
          height: 1px;
          background: #000000;
          opacity: 0.09;
          margin: 18px 0;
        }
        .paidIn{
            text-align: right;
            color: $color1;
            font-size: 12px;
            padding: 48px 0;
            .price{
                font-size: 16px;
                font-weight: bold;
            }
        }
      }
    }
  }
  .footer {
    margin-top: 18px;
    margin-bottom: 18px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #3e73fb;
    border-radius: 18px;
    color: #ffffff;
    font-size: 16px;
  }
}
</style>